
import { defineComponent } from 'vue';
import { Business } from '@/models/AgencyModel';

import Chip from '@/components/Chip.vue';
import { Picture as IconPicture } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'agency-card',
  props: {
    agency: {
      type: Object,
      default: () => {
        return {} as unknown as Business;
      },
    },
    cardClass: {
      type: String,
    },
  },
  components: {
    Chip,
    IconPicture,
  },
  data: () => ({
    readMoreActivated: [] as any,
  }),
  computed: {
    completeAddress() {
      const addressLine =
        this.agency && this.agency?.address_line
          ? this.agency.address_line
          : '';
      const city =
        this.agency && this.agency?.city ? `, ${this.agency.city}` : '';
      const state =
        this.agency && this.agency?.state ? `, ${this.agency.state}` : '';
      const postCode =
        this.agency && this.agency?.post_code
          ? ` ${this.agency.post_code}`
          : '';
      const country =
        this.agency && this.agency?.country ? `, ${this.agency.country}` : '';

      return `${addressLine} ${city} ${state} ${postCode}`;
    },

    phoneNumber() {
      return this.agency.business_phone_number;
    },
  },
  methods: {
    activateReadMore(id) {
      this.readMoreActivated[id] = (!this.readMoreActivated[id] ??
        this.readMoreActivated[id]) as any;
    },

    isReadMore(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? this.readMoreActivated[id]
        : false;
    },

    toggleCTALabel(id) {
      return typeof this.readMoreActivated[id] !== 'undefined'
        ? ' See Less'
        : ' See More';
    },

    getDescription(size = 0) {
      const descriptionValue = this.agency.description
        ? this.agency.description
        : '';
      const descStrLen = descriptionValue.length;
      const slice = size > 0 ? size : descStrLen;

      return descriptionValue.slice(0, slice);
    },

    handleVisitWebsite() {
      if (!this.agency.business_website) return;

      window.open(`https://${this.agency.business_website}`);
    },
  },
});
