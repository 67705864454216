
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import * as smartsupp from 'smartsupp-widget';

import AgencyCard from '@/components/agency/AgencyCard.vue';
import HeaderImage from '@/components/dashboard/HeaderImage.vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import {
  Getters as AgencyGetters,
  Actions as AgencyActions,
} from '@/store/enums/AgencyEnums';
import { Actions as SubActions } from '@/store/enums/SubscriptionEnums';

import DrawLoaderCard from '@/components/dashboard/DrawLoaderCard.vue';

export default defineComponent({
  name: 'agency-list',
  components: {
    HeaderImage,
    AgencyCard,
    DrawLoaderCard,
  },
  data: () => ({
    loadingAgency: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      agencies: AgencyGetters.GET_AGENCIES,
    }),
  },
  methods: {
    ...mapActions({
      fetchAgencies: AgencyActions.FETCH_AGENCIES,
      fetchSubscriptions: SubActions.FETCH_ALL_SUBSCRIPTIONS,
    }),
    async init() {
      this.loadingAgency = true;

      await this.fetchAgencies()
        .then(() => {
          this.loadingAgency = false;
        })
        .catch(() => {
          this.loadingAgency = false;
        });
    },

    handleToggleChat() {
      this.handleSmartSuppName();

      smartsupp.chatOpen();
      return false;
    },

    handleSmartSuppName() {
      const firstName = this.authUser.first_name
        ? this.authUser.first_name
        : '';
      const lastName = this.authUser.last_name ? this.authUser.last_name : '';
      const email = this.authUser.email ? this.authUser.email : '';
      const mobile = this.authUser.mobile ? this.authUser.mobile : '';

      const name = firstName && lastName ? `${firstName} ${lastName}` : '';

      smartsupp.setName(name);
      smartsupp.setEmail(email);
      smartsupp.setPhone(mobile);
    },
  },
});
