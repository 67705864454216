
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'draw-loader-card',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showTimer: {
      type: Boolean,
      default: true,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
  },
});
