import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card h-100" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "p-5" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "mb-5" }
const _hoisted_6 = {
  key: 1,
  class: "d-flex flex-row justify-content-center mb-2 gap-3"
}
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "mb-5" }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "p-5" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "mb-5" }
const _hoisted_13 = {
  key: 1,
  class: "d-flex flex-row justify-content-center mb-2 gap-3"
}
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "mb-5" }
const _hoisted_16 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3], (items, index) => {
    return _createElementVNode("div", {
      key: index,
      class: "col-lg-4 col-md-6 col-sm-12 col-xs-12 p-3"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_skeleton, {
            loading: _ctx.loading,
            animated: "",
            throttle: 500,
            class: "d-flex justify-content-center text-center flex-column p-0"
          }, {
            template: _withCtx(() => [
              _createVNode(_component_el_skeleton_item, {
                variant: "image",
                style: {"width":"100%","height":"280px","border-bottom":"1px solid #eff2f5"},
                class: "align-self-center"
              }),
              _createElementVNode("div", _hoisted_3, [
                (_ctx.showTitle)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "h3",
                          style: {"width":"50%"}
                        })
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "h3",
                          style: {"width":"75%"}
                        })
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.showTimer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      }),
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      }),
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      }),
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_el_skeleton_item, {
                    variant: "text",
                    style: {"width":"60%"}
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_el_skeleton_item, {
                    variant: "text",
                    style: {"width":"40%"}
                  })
                ]),
                (_ctx.showButton)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_el_skeleton_item, {
                        variant: "button",
                        style: {"width":"100%"}
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_skeleton_item, {
                variant: "image",
                style: {"width":"100%","height":"280px","border-bottom":"1px solid #eff2f5"},
                class: "align-self-center"
              }),
              _createElementVNode("div", _hoisted_10, [
                (_ctx.showTitle)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "h3",
                          style: {"width":"50%"}
                        })
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_el_skeleton_item, {
                          variant: "h3",
                          style: {"width":"75%"}
                        })
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.showTimer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      }),
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      }),
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      }),
                      _createVNode(_component_el_skeleton_item, {
                        variant: "rect",
                        style: {"width":"20%","height":"50px"}
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_el_skeleton_item, {
                    variant: "text",
                    style: {"width":"60%"}
                  })
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_el_skeleton_item, {
                    variant: "text",
                    style: {"width":"40%"}
                  })
                ]),
                (_ctx.showButton)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(_component_el_skeleton_item, {
                        variant: "button",
                        style: {"width":"100%"}
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ])
    ])
  }), 64))
}