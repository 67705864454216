
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: () => 'success',
    },
    text: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    badgeClass() {
      if (this.type == 'success') {
        return 'badge-light-success';
      } else if (this.type == 'primary') {
        return 'badge-light-primary';
      } else if (this.type == 'danger') {
        return 'badge-light-danger';
      } else if (this.type == 'warning') {
        return 'badge-light-warning';
      }

      return 'badge-light-success';
    },
  },
});
